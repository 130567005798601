import React from 'react'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import removeMarkdown from 'remove-markdown'
import styled from 'styled-components'
import tw from 'twin.macro'

import { MainLayout, NewMainLayout, PaginationLayout } from '@common/layouts'

export const query = graphql`
  query QuestionsByTagQuery($id: Int, $skip: Int!, $limit: Int!) {
    allQuestion(
      filter: {
        tags: { elemMatch: { id: { eq: $id } } }
      }
      sort: { fields: [publishedAt], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          title
          slug
          content
          image {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`

const StyledMainLayout = styled(MainLayout)`
  ${tw`mx-auto max-w-screen-xl`}
`

const StyledNewMainLayout = styled(NewMainLayout)`
  ${tw`mx-auto max-w-screen-xl`}
`

const ListWrapper = styled.div`
  ${tw`flex-1 grid grid-cols-1 gap-16 my-8 mx-8`}
  ${tw`md:(grid-cols-2 my-12)`}
  ${tw`lg:(grid-cols-3)`}
`

const ImageWrapper = styled.div`
  ${tw`relative w-full mb-4`}
`

const QuestionsByPage = ({ data: { allQuestion }, pageContext }) => {
  const { currentPage, numPages, name, slug } = pageContext;
  const Layout = pageContext.country === 'US' ? StyledNewMainLayout : StyledMainLayout;

  let SEODescription;
  if (allQuestion.edges.length > 0)
    SEODescription = allQuestion.edges.slice(0, 5).map((item) => item.node.title).join(' ').substring(0, 150);

  return (
    <PaginationLayout title={`Questions & Answers About ${name}`} path={`question-tag/${slug}`} currentPage={currentPage} numPages={numPages} Layout={Layout}
      description={SEODescription}>
      <ListWrapper>
        {allQuestion.edges.map(({ node: question }) => (
          <Link
            to={`/questions-and-answers/${question.slug}`}
            title={`Article: ${question.title}`}
            tw="overflow-hidden cursor-pointer"
            key={question.id}
          >
            <article>
              {question.image && (
                <ImageWrapper>
                  <Img
                    tw="h-full w-full"
                    fluid={{
                      ...question.image.childImageSharp.fluid,
                      aspectRatio: 4 / 3,
                    }}
                    alt={question.title}
                  />
                </ImageWrapper>
              )}

              <h2 tw="text-xl text-gray-900 font-bold tracking-tight leading-6 mb-2">
                {question.title}
              </h2>
              <p tw="text-sm text-gray-800 leading-6">
                {removeMarkdown(question.content).replace(
                  /^(.{80}[^\s]*).*/s,
                  '$1...'
                )}
              </p>
            </article>
          </Link>
        ))}
      </ListWrapper>
    </PaginationLayout>
  )
}

export default QuestionsByPage
